'use client';
import React, { useMemo, memo, useEffect, useState, useCallback } from 'react';
import { Button, Flex } from '../../components/ui';
import { IconWorldTwo } from '../../components/icon';
import classNames from 'classnames';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { BookingPayment } from '../../components/BookingPayment';
import { BookingHotelName } from './components/BookingHotelName';
import { BookingAuthBlock } from './components/BookingAuthBlock';
import { BookingFormGuests } from './components/BookingFormGuests';
import { BookingSelectTypePayment } from './components/BookingSelectTypePayment';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { IFormInfoInput, IFormPayInput, ILocalStorageState, IRoomGuests } from './types';
import { getCurrency } from '@common/helpers';
import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from '@app/constants/defaults';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';
import { RootState } from '@app/stores/pages/booking';
import { useHotelGuestState } from '@common/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { message } from 'antd';
import { fetchOrderEtgCreate } from '@app/api/services/shared/etgCreate';
import { fetchOstorovokTokenize } from '@app/api/services/shared/fetchOstorovokTokenize';
import { fetchOrderEtgFinish } from '@app/api/services/shared/etgFinish';
import { v4 as uuidv4 } from 'uuid';
import { fetchOrderEtgStatus } from '@app/api/services/shared/etgStatus';
import { post3DSM } from '@app/api/services/shared/post3DSM';
import { DotsLoading } from '@new/old/Icons';
import { useRoomRates } from '@common/dataContext/roomRates';
import { ymSendEvent } from '@common/helpers/ymSendEvent';
import { useAuthContext } from '@new/auth';
import { fetchCardEngage } from '@app/api/services/shared/cardEngage';
import { useGetProfile } from '@common/dataContext/profile';
import { trim } from 'lodash';

import cls from './BookingHotel.module.css';

export interface IBookingHotelProps {
	className?: string;
}

export const LOCAL_STORAGE_BOOKING = 'booking';

const defaultPay: IFormPayInput = {
	cardNumber: '',
	cvvCVC: '',
	expired: '',
	name: '',
};

export const fillGuestNames = (rooms: EtgOrderBookingFinishRoom[]): EtgOrderBookingFinishRoom[] => {
	return rooms.map(r => {
		return {
			guests: r.guests.map(g => {
				return {
					...g,
					first_name: g.first_name || r.guests[0].first_name,
					last_name: g.last_name || r.guests[0].last_name,
				};
			}),
		};
	});
};

export const convertCtxGuestsToRooms = (
	ctxGuests: RoomsGuestInfo[],
	guests: IRoomGuests
): EtgOrderBookingFinishRoom[] => {
	const guest = guests.guests[0];
	return ctxGuests.map(r => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const adults = [...Array(r.adults)].map(() => ({
			first_name: guest.first_name,
			last_name: guest.last_name,
		}));

		const children = r.children
			? r.children.map(age => ({
					first_name: guest.first_name,
					last_name: guest.last_name,
					is_child: true,
					age,
			  }))
			: [];

		return {
			guests: [...adults, ...children],
		};
	});
};

export const BookingHotelView = memo((props: IBookingHotelProps) => {
	const { className } = props;
	const { t } = useTranslation('ui');
	const hotelTranslation = useTranslation('api_hotel');
	const router = useRouter();
	const {
		query: { slug },
	} = router;
	const [urlCardId, matchHash] = slug as string[];
	const { locale } = useSelector((state: ICommonData) => state);
	const { cardDetail } = useSelector((state: RootState) => state.booking);
	const currentLocaleCode = locale?.code || DEFAULT_LOCALE;
	const breadcrumbsLinks = useMemo(() => {
		const breadcrumbs = [
			{ text: t('booking.main'), link: '/' },
			{ text: cardDetail?.nameLocation?.name, link: `/hotel/${urlCardId}` },
			{ text: t('booking.screen_title') },
		];
		return breadcrumbs.map((i, index) => {
			const isLast = breadcrumbs.length === index + 1;
			if (isLast) return <span key={index}>{i.text}</span>;

			return (
				<Link href={i.link || ''} key={index}>
					{i.text}
				</Link>
			);
		});
	}, []);
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { getHasAnonymous, clientWidth } = useAuthContext();
	const isLogin = !getHasAnonymous();
	const { data: user } = useGetProfile(isLogin);
	const isMobile = Number(clientWidth) < 1001;
	const [step, setStep] = useLocalStorage<'info' | 'payment' | 'desktop'>(
		'qvd/booking_step',
		isMobile ? 'info' : 'desktop'
	);

	const { bookGuestsState, setGuestsState } = useHotelGuestState();
	const storeData: ILocalStorageState = useMemo(() => {
		return {
			info: {
				phone: '',
				email: '',
				rooms: [{ guests: [{ first_name: '', last_name: '' }] }],
				residency: 'booking.not_specified',
				arrivalDateTime: 'booking.not_specified',
			},
		};
	}, []);
	const [bookState, setBookState] = useLocalStorage(`qvd/${LOCAL_STORAGE_BOOKING}/hotel`, storeData);
	const [hasUseAccept, setHasUserAccept] = useState<boolean>(false);
	const [paymentData, setPaymentData] = useState<IFormPayInput>(defaultPay);

	const handlerUserAccept = useCallback(
		(nextValue: boolean) => () => {
			setHasUserAccept(nextValue);
		},
		[]
	);
	const initHasEmptyMatchHash = !matchHash || matchHash.length === 0;
	const [hasEmptyMatchHash, setHasEmptyMatchHash] = useState<boolean>(initHasEmptyMatchHash);

	const { ratesData, setHasRunInterval, requestRates } = useRoomRates({
		cardId: urlCardId,
		bookGuestsState,
		enabledAutoReq: !initHasEmptyMatchHash,
	});

	const nightCount = useMemo(() => {
		return dayjs(bookGuestsState.toDate).diff(dayjs(bookGuestsState.fromDate), 'days');
	}, [bookGuestsState]);

	const room = useMemo(() => {
		return ratesData?.rates?.rates.find(f => f.match_hash === matchHash);
	}, [ratesData]);

	const isPayNow = useMemo(() => {
		if (room) {
			const [paymentOptions] = room.payment_options.payment_types;

			return paymentOptions.is_need_credit_card_data;
		}

		return false;
	}, [room]);

	const formInfoMethods = useForm<IFormInfoInput>({
		values: bookState.info,
	});
	const formPaymentMethods = useForm<IFormPayInput>({
		defaultValues: defaultPay,
	});

	useEffect(() => {
		if (!user) return;
		setBookState(bookState => {
			return {
				...bookState,
				info: {
					...bookState.info,
					phone: bookState.info.phone || user.phoneNumber || '',
					email: bookState.info.email || user.email,
					residency: bookState.info.residency === 'booking.not_specified' ? user.citizenship : 'booking.not_specified',
					rooms: bookState.info.rooms.map(i => ({
						guests: i.guests.map(guest => ({
							...guest,
							first_name: guest.first_name || user.firstName,
							last_name: guest.last_name || user.lastName,
						})),
					})),
				},
			};
		});
	}, [user]);

	const handlerBookingRoomInfo = useCallback(async () => {
		const isValidInfo = await formInfoMethods.trigger();
		const copyBookState = { ...bookState.info };
		if (isValidInfo) {
			setBookState({
				...bookState,
				info: {
					...formInfoMethods.getValues(),
					residency: copyBookState.residency,
				},
			});
		}
	}, [bookState]);

	useEffect(() => {
		const subscription = formInfoMethods.watch((value, { name, type }) => {
			setBookState({
				...bookState,
				info: value as IFormInfoInput,
			});
		});
		return () => subscription.unsubscribe();
	}, [formInfoMethods, bookState]);

	useEffect(() => {
		const subscription = formPaymentMethods.watch((value, { name, type }) => {
			setPaymentData({
				...paymentData,
				...value,
			});
		});
		return () => subscription.unsubscribe();
	}, [formPaymentMethods, paymentData]);

	const [isBookingRequest, setIsBookingRequest] = useState<boolean>(false);
	const [orderInitData, setOrderInitData] = useState<OrderUsrEtgCreateResp['data'] | undefined | null>(undefined);
	const [watchStatusTimer, setWatchStatusTimer] = useState<ReturnType<typeof setInterval> | null>(null);
	const [orderStatus, setOrderStatus] = useState<OrderUsrEtgStatusResp | null>(null);
	const [isFinishExecuted, setIsFinishExecuted] = useState<boolean>(false);
	const handlerBookingClick = useCallback(async () => {
		const isValidInfo = await formInfoMethods.trigger();
		const isValidPayment = await formPaymentMethods.trigger();

		if (!isLogin) {
			// void router.replace(`/login?redirectUrl=${router.asPath}`);

			return;
		}

		if ((!isPayNow && isValidInfo) || (isPayNow && isValidInfo && isValidPayment)) {
			ymSendEvent('booking_form', {
				hotelId: urlCardId,
				matchId: matchHash,
				room,
			});
			setIsBookingRequest(true);

			if (room) {
				const currentRate = {
					...room,
					images: [],
				};
				const arrivalDateTime =
					bookState.info.arrivalDateTime === 'booking.not_specified'
						? dayjs(bookGuestsState.fromDate).add(14, 'hours').format('YYYY-MM-DDTHH:00:00.000') + 'Z'
						: bookState.info.arrivalDateTime;

				// TODO refactor convertCtxGuestsToRooms
				const convertedRooms = convertCtxGuestsToRooms(bookGuestsState.rooms, bookState.info.rooms[0]);
				const payload: OrderUsrEtgCreateReq = {
					search_opt: {
						checkin: dayjs(bookGuestsState.fromDate).format('YYYY-MM-DD'),
						checkout: dayjs(bookGuestsState.toDate).format('YYYY-MM-DD'), // TODO: fix me
						guests: bookGuestsState.rooms,
						residency: bookState.info.residency === 'booking.not_specified' ? undefined : bookState.info.residency,
						currency: currentLocaleCode === 'ru' ? 'RUB' : 'USD',
					},
					card_id: urlCardId,
					hotel_rate: currentRate, // TODO: do we trust user there?
					arrival_datetime: arrivalDateTime,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					rooms: convertedRooms,
					user: {
						email: trim(bookState.info.email),
						phone: trim(bookState.info.phone),
						comment: trim(bookState.info.comment) || undefined,
					},
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					first_name_original: trim(bookState.info.rooms[0].guests[0].first_name),
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					last_name_original: trim(bookState.info.rooms[0].guests[0].last_name),
				};

				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const resultCreated = await fetchOrderEtgCreate(payload);
				// console.log('resultCreated', resultCreated);
				if (typeof resultCreated == 'object' && bookState.info) {
					setBookState({
						...bookState,
						lastBooked: {
							cardId: urlCardId,
							bookHash: room.book_hash,
							date: new Date(),
						},
					});
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					setOrderInitData(resultCreated.data);

					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
					const { order_id, payment_types, object_id } = resultCreated.data;

					const init_uuid = uuidv4();
					const pay_uuid = uuidv4();
					const paymentData = formPaymentMethods.getValues();

					if (isPayNow && isValidPayment) {
						const [month, year] = paymentData.expired.split('/');

						const resultTokenize = await fetchOstorovokTokenize({
							user_first_name: payload.first_name_original,
							user_last_name: payload.last_name_original,
							cvc: paymentData.cvvCVC,
							is_cvc_required: true,
							credit_card_data_core: {
								card_number: paymentData.cardNumber,
								card_holder: paymentData.name,
								year,
								month,
							},
							init_uuid,
							object_id: object_id.toString(),
							pay_uuid,
							// is_need_credit_card_data: true,
						});

						if (!resultTokenize) {
							await message.error(hotelTranslation.t('credit_card.errors.unknown_error'), 1);
						}
					}
					const resultCreatedFinish = await fetchOrderEtgFinish({
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						order_id,
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						payment_type: {
							...payment_types[0],
							init_uuid,
							pay_uuid,
						},
					});

					if (typeof resultCreatedFinish === 'boolean' && resultCreatedFinish) {
						await message.success(hotelTranslation.t('booking_result.success.title'), 1);
					} else {
						await message.error(hotelTranslation.t('credit_card.errors.unknown_error'), 1);
					}
				} else if (
					typeof resultCreated === 'string' &&
					(resultCreated === 'rate_not_found' || resultCreated === 'timeout')
				) {
					setHasEmptyMatchHash(true);
				} else {
					await message.error(hotelTranslation.t('booking_result.failure.title'), 1);
				}
				setIsFinishExecuted(true);
				setIsBookingRequest(false);
			}
		} else {
			await message.warning(hotelTranslation.t('booking.check_screen_title'));
		}
	}, [formInfoMethods, formPaymentMethods, isPayNow, room, bookState]);

	useEffect(() => {
		if ((ratesData?.status === 'success' && !room) || hasEmptyMatchHash) {
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			setTimeout(async () => {
				await message.warning(t('booking.hotel.oldData'));
			}, 0);
			setTimeout(() => {
				void router.replace(`/hotel/${urlCardId}`);
			}, 1000);
		}
	}, [ratesData, room, hasEmptyMatchHash]);

	useEffect(() => {
		if (isFinishExecuted && orderInitData?.order_id) {
			// console.log('orderInitData.order_id', orderInitData);
			const timerInterval = setInterval(() => {
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				setTimeout(async () => {
					const status = await fetchOrderEtgStatus({
						order_id: orderInitData.order_id,
					});
					// console.log('watch status', status);
					if (typeof status === 'object') {
						setOrderStatus(status);
					} else if (typeof status === 'string') {
						setOrderInitData(null);
						await message.error(status, 1);
					}
				}, 2000);
			}, 3500);
			setWatchStatusTimer(timerInterval);
		}
	}, [isFinishExecuted, orderInitData]);

	useEffect(() => {
		// TODO processing response code errors and error type
		if (orderStatus?.data && watchStatusTimer && orderStatus?.data.processing_state !== 'processing') {
			watchStatusTimer && clearInterval(watchStatusTimer);
			setWatchStatusTimer(null);
			setOrderStatus(null);

			if (orderStatus?.data.processing_state === 'completed') {
				ymSendEvent('payment_success', {
					hotelId: urlCardId,
					matchId: matchHash,
					room,
				});
				setIsBookingRequest(false);
				void router.push('/profile/orders', '/profile/orders', { shallow: false });
			}
		} else if (orderInitData === null) {
			setIsBookingRequest(false);
			setIsFinishExecuted(false);
			if (watchStatusTimer && void clearInterval(watchStatusTimer)) {
				setWatchStatusTimer(null);
			}
			setOrderInitData(undefined);
		}
	}, [orderStatus, watchStatusTimer, orderInitData]);

	useEffect(() => {
		if (orderStatus?.data.processing_state === '3ds' && orderStatus?.data.data_3ds?.action_url) {
			const params3ds = orderStatus?.data.data_3ds;
			const { data, action_url } = params3ds;

			// eslint-disable-next-line @typescript-eslint/require-await
			void (async () => {
				const resultPost3DSM = await post3DSM({ url: action_url, data });
				if (resultPost3DSM?.data) {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					document.write(resultPost3DSM?.data as string);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					window.onload({});
				} else {
					// eslint-disable-next-line no-console
					console.log('3ds error');
					setIsBookingRequest(false);
					setIsFinishExecuted(false);
				}
			})();
		}
	}, [orderStatus]);

	useEffectOnce(() => {
		if (cardDetail) {
			void fetchCardEngage({
				type: '3',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				env: cardDetail.de,
				cardId: urlCardId,
			});
		}
	});

	const handleNext = async () => {
		if (step === 'info') {
			const isValidInfo = await formInfoMethods.trigger();
			if (isValidInfo) {
				return setStep('payment');
			}
		}
		await handlerBookingClick();
	};

	return (
		<>
			<div className={classNames(cls.BookingHotel, className)}>
				<Flex className={cls.header} alignItems='center' justifyContent='between'>
					<p className={cls.BreadcrumbsLink}>{breadcrumbsLinks}</p>

					{cardDetail && cardDetail.center && (
						<Link
							href={`/search?type=hotel&center=${cardDetail.center[1]},${cardDetail.center[0]}`}
							passHref
							target='_blank'
						>
							<Flex className={cls.showMap} gap='8px' alignItems='center'>
								<IconWorldTwo />
								<p>{t('booking.showOnTheMap')}</p>
							</Flex>
						</Link>
					)}
				</Flex>
				<div className={cls.content}>
					{ratesData?.status === 'success' && room && (
						<>
							<Flex className={cls.leftColumn} gap='40px' direction='column'>
								<Flex className={cls.whiteContent} direction='column' gap='20px'>
									<BookingHotelName
										room={room}
										bookGuestsState={bookGuestsState}
										localeCode={currentLocaleCode}
										starRating={cardDetail?.ostrovok?.starRating || 0}
									/>
									{!isLogin && <BookingAuthBlock />}
									{(step === 'info' || step === 'desktop') && (
										<FormProvider {...formInfoMethods}>
											<div className={cls.bookingForm}>
												<BookingFormGuests
													bookState={bookState}
													bookGuestsState={bookGuestsState}
													formInfoMethods={formInfoMethods}
												/>
											</div>
										</FormProvider>
									)}
								</Flex>
								{(step === 'payment' || step === 'desktop') && (
									<FormProvider {...formPaymentMethods}>
										{
											// !getHasAnonymous() && isPayNow &&
											<BookingSelectTypePayment />
										}
									</FormProvider>
								)}
							</Flex>
							<div className={cls.rightColumn}>
								<BookingPayment
									nightCount={nightCount}
									room={room}
									hasUseAccept={hasUseAccept}
									onUserAccept={handlerUserAccept}
								/>
								<Button
									className={cls.btn}
									disabled={!hasUseAccept || isBookingRequest || !isLogin}
									onClick={handlerBookingClick}
								>
									{t('booking.hotel.endBooking')}
								</Button>
							</div>
							{(isBookingRequest || orderStatus?.data.processing_state === 'processing') && (
								<Flex className={cls.request} alignItems='center' justifyContent='flex-center'>
									<DotsLoading />
								</Flex>
							)}
						</>
					)}
					{ratesData?.status !== 'success' && (
						<Flex className={cls.loader} alignItems='center' justifyContent='flex-center'>
							<DotsLoading />
						</Flex>
					)}
				</div>
			</div>
			<div className={cls.info}>
				<div className={cls.infoButtons}>
					{step === 'payment' && (
						<Button outline onClick={() => setStep('info')} disabled={!hasUseAccept || isBookingRequest || !isLogin}>
							{t('booking.btn.action_back')}
						</Button>
					)}
					<Button className={cls.infoBtn} disabled={!hasUseAccept || isBookingRequest || !isLogin} onClick={handleNext}>
						{hotelTranslation.t('booking.action_next')}
					</Button>
				</div>
			</div>
		</>
	);
});

BookingHotelView.displayName = 'BookingHotelView';
